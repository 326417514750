import { defineStore } from "pinia";
import { AppConfig, UserSession } from "@stacks/connect";

const appConfig = new AppConfig(["store_write", "publish_data"]);
const userSession = new UserSession({ appConfig });
const userData = userSession.isUserSignedIn()
  ? userSession.loadUserData()
  : null;

const net = process.env.VUE_APP_NET as string;

export const useAuthStore = defineStore("auth", {
  state: () => ({
    price: 0,
    whitelist: [] as string[],
    lastId: 0,
    userData,
    userSession,
    balance: 0,
    availability: "test",
    shrooms: null as null | number,
  }),
  getters: {
    isAuthenticated: (state) => !!state.userData,
    isPrivateMember(): boolean {
      if (!this.isAuthenticated) return false;
      return this.whitelist.includes(this.userData?.profile.stxAddress[net]);
    },
  },
});
