<script lang="ts" setup></script>

<template>
  <div class="flex flex-col min-h-full">
    <div class="pt-14 flex-grow flex items-center justify-center">
      <main class="p-4">
        <router-view />
      </main>
    </div>
  </div>
</template>

<style>
html,
body,
#app {
  height: 100%;
}

#app {
  background-image: url("/bg.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
</style>
