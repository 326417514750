import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import {
  getBalance,
  getContractInfo,
  getLastId,
  getMintavailability,
} from "@/services/stacks";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
    beforeEnter: () => {
      const authStore = useAuthStore();
      if (authStore.isAuthenticated) return "/mint";
    },
  },
  {
    path: "/mint",
    name: "Mint",
    component: () => import("../views/MintView.vue"),
    beforeEnter: async () => {
      const authStore = useAuthStore();
      if (!authStore.isAuthenticated) return "/";
      await Promise.all([
        getContractInfo(),
        getLastId(),
        getBalance(),
        getMintavailability(),
      ]).then(([{ price, whitelist }, lastId, balance, availability]) => {
        authStore.$patch({
          price,
          whitelist,
          lastId,
          balance,
          availability,
        });
      });
    },
  },
  {
    path: "/success/:id",
    name: "Success",
    component: () => import("../views/SuccessView.vue"),
    beforeEnter: () => {
      const authStore = useAuthStore();
      if (!authStore.isAuthenticated) return "/";
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
