import { openContractCall, FinishedTxData } from "@stacks/connect";
import {
  FungibleConditionCode,
  makeStandardSTXPostCondition,
  callReadOnlyFunction,
  standardPrincipalCV,
  cvToValue,
} from "@stacks/transactions";
import { StacksTestnet, StacksMainnet } from "@stacks/network";
import { useAuthStore } from "@/stores/authStore";
import router from "@/router";
import axios from "axios";

const {
  VUE_APP_CONTRACT_ADDRESS,
  VUE_APP_MINT_CONTRACT_NAME,
  VUE_APP_NFT_CONTRACT_NAME,
  VUE_APP_NET,
} = process.env;

const network =
  VUE_APP_NET == "testnet" ? new StacksTestnet() : new StacksMainnet();

/* Sign Contract */
export async function signContract(fn: string, i: number): Promise<void> {
  const authStore = useAuthStore();
  const account = authStore.userData?.profile.stxAddress[VUE_APP_NET as string];
  const comparator = FungibleConditionCode.LessEqual;
  const amount = authStore.price * i;

  const standardSTXPostCondition = makeStandardSTXPostCondition(
    account,
    comparator,
    amount
  );

  const options = {
    contractAddress: VUE_APP_CONTRACT_ADDRESS as string,
    contractName: VUE_APP_MINT_CONTRACT_NAME as string,
    functionName: fn,
    functionArgs: [],
    postConditions: [standardSTXPostCondition],
    network,
    validateWithAbi: true,
    appDetails: {
      name: "Nonnish",
      icon: window.location.origin + "/example.png",
    },
    onFinish: (data: FinishedTxData) => {
      console.log("Stacks Transaction:", data.stacksTransaction);
      console.log("Transaction ID:", data.txId);
      console.log("Raw transaction:", data.txRaw);
      router.push({ name: "Success", params: { id: data.txId } });
    },
  };
  await openContractCall(options);
}

/* Gets the presale balance for the logged in user */
export async function getBalance(): Promise<number> {
  const result = await callReadOnlyFunction({
    contractAddress: VUE_APP_CONTRACT_ADDRESS as string,
    contractName: VUE_APP_MINT_CONTRACT_NAME as string,
    functionArgs: [
      standardPrincipalCV(
        useAuthStore().userData?.profile.stxAddress[VUE_APP_NET as string]
      ),
    ],
    functionName: "get-presale-balance",
    network,
    senderAddress:
      useAuthStore().userData?.profile.stxAddress[VUE_APP_NET as string],
  });

  return Number(cvToValue(result));
}

/* Gets the ID of the last minted contract */
export async function getLastId(): Promise<number> {
  const result = await callReadOnlyFunction({
    contractAddress: VUE_APP_CONTRACT_ADDRESS as string,
    contractName: VUE_APP_NFT_CONTRACT_NAME as string,
    functionArgs: [],
    functionName: "get-last-token-id",
    network,
    senderAddress:
      useAuthStore().userData?.profile.stxAddress[VUE_APP_NET as string],
  });

  return Number(cvToValue(result).value);
}

/* Gets the mint availability contract */
export async function getMintavailability(): Promise<
  "closed" | "private" | "public"
> {
  const preSaleStatus = callReadOnlyFunction({
    contractAddress: VUE_APP_CONTRACT_ADDRESS as string,
    contractName: VUE_APP_MINT_CONTRACT_NAME as string,
    functionArgs: [],
    functionName: "get-presale-status",
    network,
    senderAddress:
      useAuthStore().userData?.profile.stxAddress[VUE_APP_NET as string],
  });

  const publicSaleStatus = callReadOnlyFunction({
    contractAddress: VUE_APP_CONTRACT_ADDRESS as string,
    contractName: VUE_APP_MINT_CONTRACT_NAME as string,
    functionArgs: [],
    functionName: "get-publicsale-status",
    network,
    senderAddress:
      useAuthStore().userData?.profile.stxAddress[VUE_APP_NET as string],
  });

  const result = await Promise.all([preSaleStatus, publicSaleStatus]).then(
    ([preSale, publicSale]) => {
      if (cvToValue(publicSale)) return "public";
      if (cvToValue(preSale)) return "private";
      return "closed";
    }
  );

  return result;
}

/* Get contract price and whitelist */
interface ContractInfo {
  price: number;
  whitelist: string[];
}
export async function getContractInfo(): Promise<ContractInfo> {
  const { data } = await axios.get(
    `https://stacks-node-api.${VUE_APP_NET}.stacks.co/extended/v1/contract/${VUE_APP_CONTRACT_ADDRESS}.${VUE_APP_MINT_CONTRACT_NAME}`
  );
  const { source_code } = data;
  const price = Number(
    source_code.match(/define-constant mint-price u\d+/)[0]?.substring(28)
  );
  const whitelist = source_code
    .match(/map-set presale-count '[A-Z0-9]+/g)
    .map((x: string) => x.substring(23));
  return {
    price,
    // whitelist: [...whitelist, "ST2408C7PANMGT3G1F4EJ2WS3DW9FGRKV5JC9RC11"],
    whitelist,
  };
}
